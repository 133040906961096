import React, { useEffect, useState } from "react";
import Loading from "../Utils/Loading";
import { Card, CardDeck, Image } from "react-bootstrap";

export default function Info() {
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState(false);

    if (loading) {
        return <Loading />
    };

    return (
        <div>
            <CardDeck>


             
                    <Card.Body>
                       
                            <Card
                                className="mx-auto form-card text-center faq"
                                style={{
                                    backgroundColor: "rgb(253, 255, 255)",
                                    marginTop: "4%",
                                    marginBottom: "4%",
                                }}
                            >
                                <Card.Header>
                                    <span className="headertitle">Wat Whiskey Draw?</span>
                                </Card.Header>

          
                                    <Card.Body>
                                    <p>Whiskey Draw is an NFT based game of chance, the last NFT standing wins the game.</p>

                                    <h5>How does it work?</h5>

                                    <p>The buyer mints NFTs with a unique ticket number by purchasing ticket from the pool.</p>
                                        
                                    <p>A random ticket is drawn at a fixed time interval, if your ticket is drawn it is eliminated from the game.</p>
                                        
                                    <p>With each draw the probability of winning increases. The last standing ticket wins.</p> 

                                    <h5>Can I sell my ticket NFT on secondary marketplaces?</h5>

                                    <p>This game has interesting dynamics in secondary NFT markets.<br />What would someone <i>pay</i> for your NFT with an <i>x%</i> chance of winning a pool with a prize of <i>y</i>?</p>

                                    <h5>What are Pools?</h5>

                                    <p>A pool is a single instance of Whiskey Draw.</p>

                                    <p>On each pool creation, a smart contract gets deployed on the Avalanche Network.</p>

                                    <p>The creator of the pool is the owner of the smart contract!</p>

                                    <h5>What are Tickets?</h5>

                                    <p>Tickets are NFTs that represent your place in the game. You can buy as many tickets as you want.</p>

                                    <h5>Does Whiskey Draw get paid?</h5>
                                    <p>Yes, at the end of the pool cycle the winner can claim their winnings, 10% goes to Whiskey Draw.</p>

                                    <h5>Who else gets paid?</h5>
                                    <p>When a draw cycle ends, someone needs to initiate the draw by clicking 'Execute Draw'. That person gets 1% of the ticket price to execute the next draw. Anyone can execute the draw and get paid, even those who do not hold a ticket!</p>

                                    <h5>Why do I have too approve $WHISKEY and $BCASH to buy tickets on every pool?</h5>

                                    <p>Because each pool is an independant smart contract and each contract needs transfer approval for your tokens for a good reason, security! </p>

                                    <h5>Why is this so half assed and not finished?</h5>

                                    <p>Devs get too drunk on a daily basis.</p>
                                    <br />
                                    <p><b>Ballmer Peak Effect</b></p>
                                    <Image
                                        style={{ cursor: "pointer" }}
                                        width="50%"
                                        src="ballmer_peak.png"
                                    />

                                    <h5>What is missing/broken?</h5>

                               
                                        <p>VRF is not enabled.</p>
                                        <p>NFT images are not showing anywhere or even working.</p>
                                        <p>UI shows the wrong message before showing the correct state.</p>
                                        <p>Gas to deploy pool is high.</p>
                                        <p>Metamask libaries need updating, its like its 1999 up in here.</p>
                                        <p>Wallet prolly limited to Metamask at the moment. Have not tested any others.</p>
                                        <p>Prolly a bunch of other stupid crap.</p>
                                    
                                    <h5>How do I aquire $WHISKEY token?</h5>
                                    <p>You can't, it comes to you<a href="#/token-faucet">...</a></p>

                                    <h5>Is this a rug?</h5>
                                    <p>Likely. Everything is going to zero.</p>

                                
                                    <h5>Legal Disclaimer</h5>
                                    <p>whiskeydraw.com and its members, officers, directors, owners, employees, agents, representatives, suppliers and service providers (collectively “whiskeydraw.com”) provides this website (the “Site”) for informational purposes only. Use of and access to the Site and the information, materials, services, and other content available on or through the Site (“Content”) are subject to these terms of use and all applicable laws.</p>
                                    <br />
                                    <p>The website is for informational purposes only, you should not construe any such information or other material as legal, tax, investment, financial, or other advice. Nothing contained on our Site constitutes a solicitation, recommendation, endorsement, or offer by whiskeydraw.com or any third party service provider to buy or sell any securities or other financial instruments in this or in in any other jurisdiction in which such solicitation or offer would be unlawful under the securities laws of such jurisdiction.</p>
                                    <br />
                                    <p>All Content on this site is information of a general nature and does not address the circumstances of any particular individual or entity. Nothing in the Site constitutes professional and/or financial advice, nor does any information on the Site constitute a comprehensive or complete statement of the matters discussed or the law relating thereto. whiskeydraw.com, llc. is not a fiduciary by virtue of any person’s use of or access to the Site or Content. You alone assume the sole responsibility of evaluating the merits and risks associated with the use of any information or other Content on the Site before making any decisions based on such information or other Content. In exchange for using the Site, you agree not to hold whiskeydraw.com, its affiliates or any third party service provider liable for any possible claim for damages arising from any decision you make based on information or other Content made available to you through the Site.</p>


                                    </Card.Body>
                              
                            </Card>
                     
                    </Card.Body>
         

  
            </CardDeck>

        </div>
    );
}
