import React, { useEffect, useState } from "react";
import Loading from "../Utils/Loading";
import AlertModal from "../Utils/AlertModal";
import SuccessModal from "../Utils/SuccessModal";
import { Button, Card, CardDeck } from "react-bootstrap";

export default function TokenFaucet() {
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState(false);

    const [errorModal, setErrorModal] = useState({
        msg: "",
        open: false
    });
    const [successModal, setSuccessModal] = useState({
        msg: "",
        open: false
    });
    const [tokens] = useState([
        {
            name: "WHISKEY",
            address: "0x2575c452eDEdc0685E30e0bfc8d1f2B87EB9c18a",
            status: false
        }
    ]);

    const handleGetTestTokens = (tokenAddress) => {
        window.tokenFaucet.methods
            .claimTestTokens(tokenAddress)
            .send()
            .on('transactionHash', () => {
                setProcessing(true);
            })
            .on('receipt', (_) => {
                setProcessing(false);
            })
            .catch((error) => {
                setProcessing(false);
                setErrorModal({
                    open: true,
                    msg: error.message,
                });
            });
    }

    const checkIsAlreadyClaimed = () => {
        tokens.forEach(async (token, i) => {
            const status = await window.tokenFaucet
                .methods.alreadyClaimed(
                    window.userAddress,
                    token.address,
                ).call();

            tokens[i].status = status;

            if (i === tokens.length - 1) {
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        checkIsAlreadyClaimed();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return <Loading />
    };

    return (
        <div>
            <CardDeck>
                <Card className="hidden-card"></Card>

                <Card className="view-pool-card">
                    <Card.Body>
                        {tokens.map((token, key) => (
                            <Card
                                key={key}
                                className="mx-auto form-card text-center"
                                style={{
                                    backgroundColor: "rgb(253, 255, 255)",
                                    marginTop: "4%",
                                    marginBottom: "4%"
                                }}
                            >
                                <Card.Header>
                                    <u>Secret {token.name} Faucet</u>
                                </Card.Header>

                                {!token.status ?
                                    <Card.Body>
                                        <p>
                                            You can get 10 <strong>{token.name} </strong>
                                            (one time) by clicking below button:
                                            <br />
                                        </p>

                                        <Button
                                            style={{ marginTop: '10px' }}
                                            variant="success"
                                            onClick={() =>
                                                handleGetTestTokens(token.address)
                                            }
                                        >
                                            {processing ?
                                                <div className="d-flex align-items-center">
                                                    Processing
                                                <span className="loading ml-2"></span>
                                                </div>
                                                :
                                                <div>
                                                    GET 10 {token.name}
                                                </div>
                                            }
                                        </Button>
                                    </Card.Body>

                                    :
                                    <Card.Body>
                                        <p style={{ color: "gray" }}>
                                            You have already claimed your 10 {token.name}.
                                        </p>
                                        <p style={{ marginTop: "30px", fontWeight: "bold" }}>
                                            You drank too much.
                                        </p>
                                    </Card.Body>
                                }
                            </Card>
                        ))}
                    </Card.Body>
                </Card>

                <Card className="hidden-card"></Card>
            </CardDeck>


            <AlertModal
                open={errorModal.open}
                toggle={() => setErrorModal({
                    ...errorModal, open: false
                })}
            >
                {errorModal.msg}
            </AlertModal>

            <SuccessModal
                open={successModal.open}
                toggle={() => setSuccessModal({
                    ...successModal, open: false
                })}
            >
                {successModal.msg}
            </SuccessModal>
        </div >
    );
}
